.track-details {
  margin-top: var(--space-4);
  text-align: center;
  width: 100%;
}

.track-details.for-widget {
  margin-top: 0;
}

.track-details-cover {
  max-width: 200px;
  margin-bottom: var(--space-2);
}

.track-details-title {
  font-size: var(--font-xlarge);
  color: var(--accent-color);
}

.lyrics {
  margin-top: var(--space-2);
  font-size: var(--font-regular);
  white-space: pre;
  text-wrap: pretty;
}
