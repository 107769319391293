.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

@madia only screen and (max-height: 960px) {
  .modal-overlay {
    padding: var(--space-6);
  }
}

.modal-content {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  height: 100%;
  /* overflow-y: auto; */
}

/* remove scrollbar style  */
.modal-content::-webkit-scrollbar {
  display: none;
}
