/* Ajouter dans votre App.css ou un fichier CSS séparé */

.music-player {
  /* Styles du lecteur */
  width: 100%;
}

.player-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0px;
}

.control-icon {
  font-size: 24px;
  cursor: pointer;
  color: var(--primary-color);
}

.control-icon.active {
  color: var(--accent-color);
}

.control-icon.disabled {
  color: rgba(255, 255, 255, 0.5);
}

.circle-loader-icon {
  font-size: 24px;
  color: var(--primary-color);
}

.loop-icon-number {
  position: absolute;
  top: 1px;
  right: 2px;
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-color);
}

.loop-icon-number.active {
  color: var(--accent-color);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  pointer-events: none; /* Important pour ne pas bloquer les événements de la souris */
  opacity: 0;
}

.line {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  opacity: 0;
}

@media only screen and (min-width: 958px) {
}

@media only screen and (max-width: 958px) {
  /* Mettre les icons plus gros */
  .control-icon {
    font-size: 42px;
  }

  .line {
    display: none;
  }

  .overlay {
    display: none;
  }
}
