/* ImageLoaderEffect.css */

.image-loader-container {
  animation: loading 5s infinite;
}
/* create infinite opacity loading animation, should be smooth and not too fast, a bit like game loading */
@keyframes loading {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
