/* /src/styles/variables.css */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700&display=swap");
/* import Roboto also */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  min-height: -webkit-fill-available;
}

body {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

/* Couleurs et variables */
:root {
  --primary-color: #283593; /* Bleu foncé */
  --primary-color-8: rgba(40, 53, 147, 0.8); /* Bleu foncé */
  --secondary-color: #f9a825; /* Jaune */
  --accent-color: #c62828; /* Rouge */
  --background-color: #212121; /* Noir */
  --text-color: #ffffff; /* Blanc */
  --tertiary-color: #6a1b9a; /* Violet */
  --neutral-color: #757575; /* Gris */
  --neutral-color-8: rgba(117, 117, 117, 0.8); /* Gris */
  --font-title: "Roboto", sans-serif;
  --font-text: "Open Sans", sans-serif;
}

/* Tailles de police */
:root {
  --font-small: 0.8rem;
  --font-regular: 1rem;
  --font-large: 1.2rem;
  --font-xlarge: 1.5rem;
}

/* Espacements (marge et padding) */
:root {
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 28px;
  --space-8: 32px;
  --space-9: 36px;
  --space-10: 40px;
}

/* Espacement : Marge (Margin) */
.mt-1 {
  margin-top: var(--space-1);
}
.mb-1 {
  margin-bottom: var(--space-1);
}
.ml-1 {
  margin-left: var(--space-1);
}
.mr-1 {
  margin-right: var(--space-1);
}

.mt-2 {
  margin-top: var(--space-2);
}
.mb-2 {
  margin-bottom: var(--space-2);
}
.ml-2 {
  margin-left: var(--space-2);
}
.mr-2 {
  margin-right: var(--space-2);
}

.mt-3 {
  margin-top: var(--space-3);
}
.mb-3 {
  margin-bottom: var(--space-3);
}
.ml-3 {
  margin-left: var(--space-3);
}
.mr-3 {
  margin-right: var(--space-3);
}

.mt-4 {
  margin-top: var(--space-4);
}
.mb-4 {
  margin-bottom: var(--space-4);
}
.ml-4 {
  margin-left: var(--space-4);
}
.mr-4 {
  margin-right: var(--space-4);
}

.mt-5 {
  margin-top: var(--space-5);
}
.mb-5 {
  margin-bottom: var(--space-5);
}
.ml-5 {
  margin-left: var(--space-5);
}
.mr-5 {
  margin-right: var(--space-5);
}

.mt-6 {
  margin-top: var(--space-6);
}
.mb-6 {
  margin-bottom: var(--space-6);
}
.ml-6 {
  margin-left: var(--space-6);
}
.mr-6 {
  margin-right: var(--space-6);
}

.mt-7 {
  margin-top: var(--space-7);
}
.mb-7 {
  margin-bottom: var(--space-7);
}
.ml-7 {
  margin-left: var(--space-7);
}
.mr-7 {
  margin-right: var(--space-7);
}

.mt-8 {
  margin-top: var(--space-8);
}
.mb-8 {
  margin-bottom: var(--space-8);
}
.ml-8 {
  margin-left: var(--space-8);
}
.mr-8 {
  margin-right: var(--space-8);
}

.mt-9 {
  margin-top: var(--space-9);
}
.mb-9 {
  margin-bottom: var(--space-9);
}
.ml-9 {
  margin-left: var(--space-9);
}
.mr-9 {
  margin-right: var(--space-9);
}

.mt-10 {
  margin-top: var(--space-10);
}
.mb-10 {
  margin-bottom: var(--space-10);
}
.ml-10 {
  margin-left: var(--space-10);
}
.mr-10 {
  margin-right: var(--space-10);
}

/* Espacement : Padding */
.pt-1 {
  padding-top: var(--space-1);
}
.pb-1 {
  padding-bottom: var(--space-1);
}
.pl-1 {
  padding-left: var(--space-1);
}
.pr-1 {
  padding-right: var(--space-1);
}

.pt-2 {
  padding-top: var(--space-2);
}
.pb-2 {
  padding-bottom: var(--space-2);
}
.pl-2 {
  padding-left: var(--space-2);
}
.pr-2 {
  padding-right: var(--space-2);
}

.pt-3 {
  padding-top: var(--space-3);
}
.pb-3 {
  padding-bottom: var(--space-3);
}
.pl-3 {
  padding-left: var(--space-3);
}
.pr-3 {
  padding-right: var(--space-3);
}

.pt-4 {
  padding-top: var(--space-4);
}
.pb-4 {
  padding-bottom: var(--space-4);
}
.pl-4 {
  padding-left: var(--space-4);
}
.pr-4 {
  padding-right: var(--space-4);
}

.pt-5 {
  padding-top: var(--space-5);
}
.pb-5 {
  padding-bottom: var(--space-5);
}
.pl-5 {
  padding-left: var(--space-5);
}
.pr-5 {
  padding-right: var(--space-5);
}

.pt-6 {
  padding-top: var(--space-6);
}
.pb-6 {
  padding-bottom: var(--space-6);
}
.pl-6 {
  padding-left: var(--space-6);
}
.pr-6 {
  padding-right: var(--space-6);
}

.pt-7 {
  padding-top: var(--space-7);
}
.pb-7 {
  padding-bottom: var(--space-7);
}
.pl-7 {
  padding-left: var(--space-7);
}
.pr-7 {
  padding-right: var(--space-7);
}

.pt-8 {
  padding-top: var(--space-8);
}
.pb-8 {
  padding-bottom: var(--space-8);
}
.pl-8 {
  padding-left: var(--space-8);
}
.pr-8 {
  padding-right: var(--space-8);
}

.pt-9 {
  padding-top: var(--space-9);
}
.pb-9 {
  padding-bottom: var(--space-9);
}
.pl-9 {
  padding-left: var(--space-9);
}
.pr-9 {
  padding-right: var(--space-9);
}

.pt-10 {
  padding-top: var(--space-10);
}
.pb-10 {
  padding-bottom: var(--space-10);
}
.pl-10 {
  padding-left: var(--space-10);
}
.pr-10 {
  padding-right: var(--space-10);
}

/* Tailles */
:root {
  --size-1: 4px;
  --size-2: 8px;
  --size-3: 12px;
  --size-4: 16px;
  --size-5: 20px;
  --size-6: 24px;
  --size-7: 28px;
  --size-8: 32px;
  --size-9: 36px;
  --size-10: 40px;
}

/* Tailles : Largeur */
.w-1 {
  width: var(--size-1);
}
.w-2 {
  width: var(--size-2);
}
.w-3 {
  width: var(--size-3);
}
.w-4 {
  width: var(--size-4);
}
.w-5 {
  width: var(--size-5);
}
.w-6 {
  width: var(--size-6);
}
.w-7 {
  width: var(--size-7);
}
.w-8 {
  width: var(--size-8);
}
.w-9 {
  width: var(--size-9);
}
.w-10 {
  width: var(--size-10);
}

/* Tailles :

/* Flexbox */
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}

/* Alignement Flex */
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}

/* Gap pour Flexbox */
.gap-1 {
  gap: var(--space-1);
}
.gap-2 {
  gap: var(--space-2);
}
.gap-3 {
  gap: var(--space-3);
}
.gap-4 {
  gap: var(--space-4);
}
.gap-5 {
  gap: var(--space-5);
}
.gap-6 {
  gap: var(--space-6);
}

/* Remplacer 'x' par les numéros correspondants pour les espacements */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* make rotation animation for icons such as circles for loaders */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* find a good class name for elements using rotate animation */
.rotate {
  animation: rotate 1s linear infinite;
}
